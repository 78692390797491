"use strict";
  
// Sélectionner tous les éléments du projet
const projectElements = document.querySelectorAll('.projet__el');

const closeButtons = document.querySelectorAll('.closePopup');

projectElements.forEach(element => {
    element.addEventListener('click', () => {
        const popupClass = element.getAttribute('data-popup');
        const popup = document.querySelector(`.${popupClass}`);
        if (popup) {
            popup.classList.toggle('popup-modal--visible');
        }
    });
});

closeButtons.forEach(button => {
    button.addEventListener('click', () => {
        const popup = button.closest('.popup-modal');
        if (popup) {
            popup.classList.toggle('popup-modal--visible');
        }
    });
});

  

  
// boutton competences

document.querySelectorAll('.btn__competences').forEach(button => {
  button.addEventListener('click', () => {
      
      toggleMenu();
      toggleFleche();
  });
});

function toggleMenu() {
  const popupModal = document.getElementById('mesCompetence');
  popupModal.classList.toggle('show--competence');
  const animatedElements = document.querySelectorAll('.technologie__el');
  animatedElements.forEach(element => {
    element.classList.toggle('animation--icones');
  });
  
}

function toggleFleche() {
    const fleche = document.querySelectorAll('.fleche');
    fleche.forEach(fleche => {
        fleche.classList.toggle('fleche--close');
    });
}



// année
var annee = new Date().getFullYear();
document.querySelector(".annee").innerHTML = annee;



// slide


const slider = document.querySelector('.slider');
const slides = document.querySelectorAll('.projet__el');
const prevButton = document.querySelector('.prev');
const nextButton = document.querySelector('.next');

let currentIndex = 0;
const slidesToShow = 4;
const totalSlides = slides.length;

const updateSliderPosition = () => {
  const offset = -currentIndex * (150 / slidesToShow);
  slider.style.transform = `translateX(${offset}%)`;
};

prevButton.addEventListener('click', () => {
  if (currentIndex > 0) {
    currentIndex--;
  } else {
    currentIndex = Math.max(totalSlides - slidesToShow, 0);
  }
  updateSliderPosition();
});

nextButton.addEventListener('click', () => {
  if (currentIndex < totalSlides - slidesToShow) {
    currentIndex++;
  } else {
    currentIndex = 0;
  }
  updateSliderPosition();
});



// anime écriture

document.addEventListener("DOMContentLoaded", function() {
  const textElement = document.querySelector('.title--anim');
  const text = textElement.textContent;
  textElement.textContent = '';
  let index = 0;

  function type() {
      if (index < text.length) {
          textElement.textContent += text.charAt(index);
          index++;
          setTimeout(type, 100); 
      }
  }

  type();
});




// scroll annime



const scale = document.querySelectorAll('.textTransl');
function animateTransl() {
  for (let i = 0; i < scale.length; i++) {
    const divPosition = scale[i].getBoundingClientRect().top;
    const screenHeight = window.innerHeight / 1;
    if (divPosition < screenHeight) {
      scale[i].classList.add('show-textTransl');
    }
  }
}

const title = document.querySelectorAll('.textTranslx');

function animateTitle() {
  for (let i = 0; i < title.length; i++) {
    const titlePosition = title[i].getBoundingClientRect().top;
    const screenHeight = window.innerHeight / 1.3;
    if (titlePosition < screenHeight) {
      title[i].classList.add('show-textTranslx');
    }
  }
}

document.addEventListener('DOMContentLoaded', function () {
  animateTransl();
  animateTitle();
});

window.addEventListener('scroll', animateTransl);
window.addEventListener('scroll', animateTitle);
